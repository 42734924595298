import { policyList } from "@/enums/permissions";
import { MemberLevel } from "@/interfaces/memberLevel";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { ActionSubheader, TableAction } from "helix-vue-components";
import { memberLevelService } from "./memberLevel.service";

class MemberLevelActionService {
  public getGeneralActions(filtersCallback: () => void): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        id: "btn_create_member_lvl",
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "member-level-add"
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createMemberLevelPricing
          )
      },
      {
        icon: "fal fa-search",
        id: "btn_search_member_lvl",
        otherComponent: {
          name: "TableSearchComponent",
          action: memberLevelService.searchEvent()
        }
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: store.getters["PermissionsModule/hasPermission"](
          policyList.modifyMemberLevelPricing
        )
          ? "fal fa-pen"
          : "fal fa-eye",
        action: memberLevel => {
          store.dispatch("RouterModule/go", {
            name: store.getters["PermissionsModule/hasPermission"](
              policyList.modifyMemberLevelPricing
            )
              ? "member-level-edit"
              : "member-level-view",
            params: {
              id: memberLevel.id,
              currentModel: memberLevel
            }
          });
        }
      },
      {
        icon: "",
        switch: "is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableMemberLevelPricing
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: String(
            i18n.t("member_level_pricing.toggle_active_confirm")
          ),
          modalSuccessText: "yes",
          modalSuccessAction: ({ item, unselectModal, modelValue }) => {
            memberLevelService
              .toggleMemberLevels(item, modelValue)
              .then(() => unselectModal())
              .finally(() =>
                store.dispatch("MemberLevelModule/loadMemberLevels")
              );
          },
          modalCancelText: "No",
          modalIdCancel: "btn_delete_no"
        }
      }
    ];
  }

  public getMultiActions(): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        action(memberLevel: MemberLevel[]) {
          store.dispatch("MemberLevelModule/enableMultiple", memberLevel);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableMemberLevelPricing
          )
      },
      {
        icon: "fal fa-eye-slash",
        action(memberLevel: MemberLevel[]) {
          store.dispatch("MemberLevelModule/disableMultiple", memberLevel);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableMemberLevelPricing
          )
      }
    ];
  }
}

export const memberLevelActionService = new MemberLevelActionService();
