import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { HttpQuery } from "@/interfaces/httpQuery";
import { MemberLevel } from "@/interfaces/memberLevel";
import { pusherEvents } from "@/internal";
import { MemberLevelTableMetadata } from "@/metadata/memberLevel";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { memberLevelActionService } from "@/services/memberLevel.action.service";
import { memberLevelService } from "@/services/memberLevel.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  ActionSubheader,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./MemberLevelPricingList.template.vue";

const namespace: string = "MemberLevelModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(memberLevelService)
export default class MemberLevelPricingListComponent extends Vue {
  @Getter("memberLevels", { namespace })
  public memberLevels!: MemberLevel[];
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("loadMemberLevels", { namespace })
  public loadMemberLevelsAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public headers: TableHeader[] = MemberLevelTableMetadata;
  public currentFilter: { active?: boolean } | null = null;
  public enableMultiActions = true;
  public showFilters = false;
  public activeFilter = false;
  public generalActions: ActionSubheader[] = memberLevelActionService.getGeneralActions(
    this.filtersCallback
  );
  public multiActions: TableAction[] = memberLevelActionService.getMultiActions();
  public rowActions: TableAction[] = memberLevelActionService.getRowActions();

  protected query: HttpQuery = {
    ...INITIAL_QUERY_STATE
  };

  public filter(header: TableHeader) {
    memberLevelService.sortQuery(header);
  }

  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.triggerSearch({ "q[is_active_eq]": 1 });
    }
  }

  public async triggerSearch(query?: HttpQuery) {
    memberLevelService.setQuery(query);
    this.loadMemberLevelsAction();
  }

  /**
   * Triggers search for qualified/non qualified member Level.
   * @param header: TableHader
   */

  public onMemberLevelsFilter(active: boolean) {
    this.activeFilter = active;
    const param = active
      ? { "q[is_active_eq]": 1 }
      : { "q[is_active_is_false]": 1 };
    this.triggerSearch(param);
  }

  public back() {
    this.$router.push("/inventory/pricing");
  }

  public changePagination(pagination: TablePagination) {
    const paginate = memberLevelService.paginationAction();
    paginate(pagination);
  }

  protected async mounted() {
    this.setPageNav({
      title: "member_level_pricing.title",
      rightActions: {
        generalActions: () => this.generalActions
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      }
    });
    this.triggerSearch({ ...this.query, "q[is_active_eq]": 1 });
    this.$changes.watch(
      pusherEvents.memberLevelTouched,
      this.loadMemberLevelsAction
    );
    this.enableMultiActions = this.hasPermission(
      policyList.enableDisableMemberLevelPricing
    );
  }
}
